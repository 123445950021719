<template>
  <section>
   <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-primary"
        class="mb-1"
        size="sm"
        @click.prevent="selectAll"
      >
        Alles {{selected.length  == groups.length ? 'deselecteren' : 'selecteren'}}
      </b-button>
    <b-list-group>
      <draggable v-model="groups" group="groups">
        <b-list-group-item :key="g.id" v-for="g in groups" class="d-flex">
          <span>
            <b-form-checkbox
            name="selected-items"
            v-model="selected"
            :value="g.id"
          ></b-form-checkbox>
          </span>
          <span class="mr-1">
            <b-avatar
              size="20px"
              :variant="`light-${g.active ? 'success' : 'danger'}`"
              :text="groupInitials(g)"
              :src="g.logo ? g.logo.small : null"
              :disabled="loading"
            />
          </span>
          <span>{{ g.name }}</span>
        </b-list-group-item>
      </draggable>
    </b-list-group>
    <div class="d-flex justify-content-between">
      <div>
         <b-dropdown
            variant="outline-primary"
            bordered
            class="bg-white mt-2"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              Acties
            </template>
            
            <b-dropdown-item @click="activateGroups" variant="success">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">{{selected.length}} groepen activeren</span>
            </b-dropdown-item>
             <b-dropdown-item @click="deactivateGroups" variant="warning">
              <feather-icon icon="EyeOffIcon" />
              <span class="align-middle ml-50">{{selected.length}} groepen deactiveren</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeGroups" variant="danger">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{selected.length}} groepen verwijderen</span>
            </b-dropdown-item>
          </b-dropdown>
      </div>
      <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="mt-2"
        @click.prevent="cancel"
      >
        Annuleren
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 ml-1"
        :disabled="loading"
        @click.prevent="saveOrder"
      >
        <b-spinner v-if="loading" small></b-spinner>
        Opslaan
      </b-button>
      </div>
      
    </div>
  </section>
</template>

<script>
import { db, functions } from "@/firebase/firebaseConfig";
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardFooter,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BButton,
  BLink,
  BModal,
  BListGroup,
  BListGroupItem,
  BSpinner
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardFooter,
    BFormCheckbox,
     BDropdown,
  BDropdownItem,
    BButton,
    BLink,
    BModal,
    BAvatar,
    BListGroup,
    BListGroupItem,
    FeatherIcon,
    draggable,
    BSpinner
  },
  data() {
    return {
      data: {},
      selected: [],
      groups: [...this.$store.state.groups, ...[]],
      loading: false,
    };
  },
  directives: {
    Ripple,
  },
  created() {},
  computed: {
    selectedGroups(){
      return this.groups.filter((e) => this.selected.indexOf(e.id) > -1 );
    }
  },
  methods: {
    saveOrder() {
      this.loading = true;
      this.$store.dispatch("updateGroupOrder", {
        groups: this.groups,
        notify: (arg) => this.$toast(arg),
      }).then(() => {
          this.loading = false;
          this.$emit("close");
      })
    },
    activateGroups(){
      this.loading = true;
      this.$store.dispatch("updateGroupActive", {
        groups: this.selectedGroups,
        active: true,
        notify: (arg) => this.$toast(arg),
      }).then(() => {
          this.loading = false;
          this.$emit("close");
      })
    },
    deactivateGroups(){
      this.loading = true;
      this.$store.dispatch("updateGroupActive", {
        groups: this.selectedGroups,
        active: false,
        notify: (arg) => this.$toast(arg),
      }).then(() => {
          this.loading = false;
          this.$emit("close");
      })
    },
    removeGroups(){
      this.loading = true;
      this.$store.dispatch("removeGroups", {
        groups: this.selectedGroups,
        notify: (arg) => this.$toast(arg),
      }).then(() => {
          this.loading = false;
          this.$emit("close");
      })
    },
    selectAll(){
      if(this.selected.length  == this.groups.length){
        this.selected = [];
      } else {
        this.selected = this.groups.map((val) => val.id);
      }
    
  },
    cancel() {
      this.groups = [...this.$store.state.groups, ...[]];
      this.$emit("close");
    },
    groupInitials(g) {
      let name = g.name;
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();

      return initials;
    },
  },
};
</script>
