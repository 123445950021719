<template>
  <section>
    <div class="d-flex justify-content-between mb-2">
      <h2>Groepen</h2>
      <div>
      <b-button variant="primary" class="btn-icon mr-1" @click="getVerificationLetters"><b-spinner v-if="loading" class="mr-1" small></b-spinner><feather-icon icon="DownloadIcon" /></b-button>
      <b-button variant="primary" class="btn-icon" @click="showOrderGroups = true"><feather-icon icon="SettingsIcon" /></b-button>
      </div>
    </div>
    <b-row class="match-height d-flex">
      
      <b-col
        lg="3"
        md="3"
        :key="g.id"
        v-for="g in activeGroups"
      >
      <b-link :to="{name: 'group-detail', params: {id: g.id}}">
        <b-card
        
          :style="`background-color: ${g.color || '#1f2185'}`"
          text-variant="white"
        >
          <b-card-title class="text-white">
            {{ g.name }}
          </b-card-title>
          
        </b-card>
      </b-link>
      </b-col>
      <b-col
        lg="3"
        md="3"
      >
      <b-link @click="showNewGroup=true">
        <b-card>
          <b-card-title>
            <feather-icon icon="PlusIcon" />
            Nieuwe groep
          </b-card-title>
          
        </b-card>
      </b-link>
      </b-col>
    </b-row>
    <div v-if="inactiveGroups.length > 0">
    <div class="d-flex justify-content-between mb-2">
      <h2>Inactieve Groepen</h2>
    </div>
    <b-row class="match-height d-flex">
      
      <b-col
        lg="3"
        md="3"
        :key="g.id"
        v-for="g in inactiveGroups"
      >
      <b-link :to="{name: 'group-detail', params: {id: g.id}}">
        <b-card
        
          :style="`background-color: white`"
        >
          <b-card-title :style="`color: ${g.color || '#1f2185'}`">
            {{ g.name }}
          </b-card-title>
          
        </b-card>
      </b-link>
      </b-col>
    </b-row>
    </div>
    <b-modal 
    hide-footer
    
    v-model="showNewGroup">
      <group-edit @close="showNewGroup = false" />
    </b-modal>
     <b-modal 
    hide-footer
    title="Volgorde aanpassen"
    v-model="showOrderGroups">
      <edit-group-order @close="showOrderGroups = false" />
    </b-modal>
    <pdf-generator :data="{ver: letterData, g: this.$store.state.groups}" :type="type" :ref="`pdfGenerator`" :multiple="true" :hidden="true"></pdf-generator>

  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardTitle, BCardFooter, BButton, BLink, BModal, BSpinner } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import PdfGenerator from '@/components/PdfGenerator.vue';
import GroupEdit from './group-edit/GroupEdit';
import EditGroupOrder from './EditGroupOrder';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { db, functions } from '@/firebase/firebaseConfig';

export default {
  components: {
    GroupEdit,
    BRow,
    BCol,
    BSpinner,
    BCard,
    BCardTitle, BCardFooter, BButton,BLink,
    BModal,
    FeatherIcon,
    EditGroupOrder,
    PdfGenerator
  },
  data() {
    return {
      data: {},
      loading: false,
      showNewGroup: false,
      showOrderGroups: false,
      letterData: [],
      type: 'group',
    }
  },
  computed:{
    activeGroups(){
      return this.$store.state.groups.filter((e) => e.active != false);
    },
     inactiveGroups(){
      return this.$store.state.groups.filter((e) => (e.active == false));
    }
  },
  created() {
   
  },
  methods: {
    async getVerificationLetters() {
      this.loading = true;
      this.$toast({
            component: ToastificationContent,
            props: {
              title: `Posters aanmaken gestart`,
              text: `Opgelet, afhankelijk van het aantal groepen kan dit even duren.`,
              icon: "WarningCircleIcon",
              variant: "warning",
            },
          });
      var snap = await db.collection('verification').where('organisationId', '==', this.$store.state.organisation.id).where('type', '==', 'group').get();
      this.letterData = snap.docs.map(d => d.data());
      setTimeout(async () => {
        try{
          await this.$refs.pdfGenerator.generatePDF();
          this.loading = false;
        } catch(error){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              text: error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          this.loading = false;
        }
        
        
      }, 10)
    },
  }
}
</script>
