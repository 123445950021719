<template>
  <div>
     <b-row v-if="stats.userCount > 0">
       <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="UsersIcon"
          color="primary"
          :statistic="stats.userCount"
          :statistic-title="`${getRoleMultiple('member')}, ${getRoleMultiple('leader')} &amp; ${getRoleMultiple('admin')}`"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="UsersIcon"
          color="primary"
          :statistic="activeParents"
          :statistic-title="`Ouders`"
        />
      </b-col>
          <b-col  lg="3"
        sm="6">
          <main-precentage :percentage="stats.active" title="Active gebruikers" :subtitle="`${stats.activeUsersCount} actieve gebruikers`" />
          </b-col>
          <b-col  lg="3"
        sm="6">
          <main-precentage :percentage="stats.activeParents" title="Gebruikers met actieve ouders" :subtitle="`${stats.activeParentsCount} gebruikers met min. 1 actieve ouder`" />

          </b-col>
          
        </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from 'bootstrap-vue'

import GroupList from '@/views/groups/GroupList';

import MainPrecentage from '@/components/MainPrecentage.vue';

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

import mainMixin from '@/mixins/mainMixin';

export default {
  mixins: [mainMixin],
  components: {
    BCard,
    BCardText,
    BLink,
    GroupList,
    MainPrecentage,
    StatisticCardWithAreaChart,
    BRow, BCol
  },
  data(){
    return{
      stats: {
        active: 0,
        activeUsersCount: 0,
        activeParents: 0,
        activeParentsCount: 0,
        userCountSeries: {name: 'Gebruikers', data: []},
        userCount: this.$store.state.organisation ? this.$store.state.organisation.userCount : 0
      },
      children: {},
      uids: {}
    }
  },

  created(){
    this.fetchUsers();
    this.fetchcontacts();
  },
  computed: {
    activeParents(){
      return Object.keys(this.uids).length;
    }
  },
  methods: {
    calculateStats(){
      var sum = 0;
      var sumParents = 0;
      this.$store.state.userManagement.users.forEach(u => {
        if(u.uid) sum++;
        var i = this.$store.state.userManagement.contacts.findIndex(e => e.children.includes(u.uoid));
        if(i > -1 && this.$store.state.userManagement.contacts[i].uid){
          sumParents++;
        }
      })
      this.stats.active = Math.round((sum/this.$store.state.userManagement.users.length*100) * 100) / 100;
      this.stats.activeParents = Math.round((sumParents/this.$store.state.userManagement.users.filter(u => u.type == 'member').length*100) * 100) / 100;
      this.stats.userCountSeries.data = [0, Math.floor(this.stats.userCount/6), Math.floor(this.stats.userCount/3*2),  this.stats.userCount]
      this.stats.activeUsersCount = sum;
      this.stats.activeParentsCount = sumParents;
    },


    fetchcontacts() {
      if (
        this.$store.state.userManagement.contacts &&
        this.$store.state.userManagement.contacts.length > 1
      ) {
        this.matchChildren();
        this.calculateStats();
       
      } else {
        this.$store
          .dispatch(
            "userManagement/fetchAllContacts",
            this.$store.state.organisation.id
          )
          .then(() => {
            this.matchChildren();
            this.calculateStats();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    matchChildren() {
      this.$store.state.userManagement.contacts.forEach((c) => {
        if (c.children) {
          c.children.forEach((ch) => {
            var i = this.$store.state.userManagement.users.findIndex(
              (e) => e.uoid == ch
            );
            if (i > 0) {
              this.uids[c.uid] = true;
              this.children[ch] = this.$store.state.userManagement.users[i];
            } else {
              console.log(ch);
            }
          });
        }
      });
    },
    fetchUsers() {
      if (
        !(
          this.$store.state.userManagement.users &&
          this.$store.state.userManagement.users.length > 1
        )
      ) {
        this.$store.dispatch(
          "userManagement/fetchUsers",
          this.$store.state.organisation.id
        );
      }
    },
    
  }
}
</script>

<style>

</style>
