<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="d-flex align-items-center mb-2">
      <feather-icon icon="PlusIcon" size="18" />
      <h4 class="mb-0 ml-75">Nieuwe groep</h4>
    </div>
    <!-- form -->
    <b-form class="mt-2" @submit.prevent="saveGroup">
      <b-row>
        <b-col sm="12">
          <div class="d-flex align-items-center mb-2 mt-2">
            <feather-icon icon="Edit2Icon" size="18" />
            <h4 class="mb-0 ml-75">Groepsnaam</h4>
          </div>
        </b-col>
        <b-col sm="12">
          <b-form-group label-for="g-name">
            <b-form-input
              v-model="g.name"
              :class="$v.g.name.$error ? 'is-invalid' : ''"
              placeholder="Groepsnaam"
              name="g-name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <div class="d-flex align-items-center mb-2 mt-2">
            <feather-icon icon="DropletIcon" size="18" />
            <h4 class="mb-0 ml-75">Kleur</h4>
          </div>
        </b-col>
        <b-col sm="12">
          <b-form-group label-for="g-primaryColor">
            <input-color-picker
              v-model="g.color"
              :class="$v.g.color.$error ? 'is-invalid' : ''"
              name="g-primaryColor"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <div class="d-flex align-items-center mb-2 mt-2">
            <feather-icon icon="CheckIcon" size="18" />
            <h4 class="mb-0 ml-75">Actief</h4>
          </div>
        </b-col>
        <b-col sm="12">
          <b-form-group label-for="g-active">
            <b-form-checkbox
              v-model="g.active"
              placeholder="Actief"
              name="g-active"
            >{{g.active ? 'Actief' : 'Niet actief'}}</b-form-checkbox>
            
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="loading"
            type="submit"
          >
            <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="cancelForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import InputColorPicker from "vue-native-color-picker";
import { required } from "vuelidate/lib/validators";
import { db } from "@/firebase/firebaseConfig";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCardText,
  BLink,
  BSpinner,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "GroupEdit",
  components: {
    InputColorPicker,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardText,
    BLink,
    BSpinner,
    BAlert,
    BOverlay,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      g: {
        name: '',
        color: null,
        active: true
      },
    };
  },
  methods: {
    saveGroup() {
      this.addGroup();
    },
    addGroup() {
      if (!this.$v.$invalid) {
        this.loading = true;
        this.g.organisationId = this.$store.state.organisation.id;
        this.g.organisationName = this.$store.state.organisation.shortName;
        this.g.type = "members";
        this.g.o = this.$store.state.groups.length;
        db.collection("organisations")
          .doc(this.$store.state.organisation.id)
          .collection("groups")
          .add(this.g)
          .then((res) => {
            
            this.$store.dispatch(
              "loadGroups",
              this.$store.state.organisation.id
            );
            this.loading = false;
            this.$emit('close');
            this.resetForm();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Groep toegevoegd!',
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error!',
                text: error.message,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$v.$touch();
      }
    },
    cancelForm(){
        this.resetForm();
        this.$emit('close');
    },
    resetForm() {
        this.g = {
            name: null
        }
    },
  },
  validations: {
    g: {
      name: {
        required,
      },
      color: {},
    },
  },
};
</script>

<style>
</style>