<template>
  <div>
     <statistics/>
    <group-list/>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from 'bootstrap-vue'

import GroupList from '@/views/groups/GroupList';

import Statistics from '@/views/Statistics.vue'

import MainPrecentage from '@/components/MainPrecentage.vue';

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

import mainMixin from '@/mixins/mainMixin';

export default {
  mixins: [mainMixin],
  components: {
    BCard,
    BCardText,
    BLink,
    GroupList,
    Statistics,
    MainPrecentage,
    StatisticCardWithAreaChart,
    BRow, BCol
  },
  data(){
    return{
      stats: {
        active: 0,
        userCountSeries: {name: 'Gebruikers', data: []},
        userCount: this.$store.state.organisation ? this.$store.state.organisation.userCount : 0
      }
    }
  },

  created(){
    if(this.$store.state.organisation && this.$store.state.userManagement.users.length < 1){
      this.$store.dispatch('userManagement/fetchUsers', this.$store.state.organisation.id).then(() => {
        this.calculateStats();
      })
    } else {
      this.calculateStats();
    }
  },
  methods: {
    calculateStats(){
      var sum = 0;
      this.$store.state.userManagement.users.forEach(u => {
        if(u.uid) sum++;
      })

      this.stats.active = Math.round((sum/this.$store.state.userManagement.users.length*100) * 100) / 100;

      this.stats.userCountSeries.data = [0, Math.floor(this.stats.userCount/6), Math.floor(this.stats.userCount/3*2),  this.stats.userCount]

    }
  }
}
</script>

<style>

</style>
